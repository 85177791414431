@import "./utils/AdditionalVariables.scss";

/* Start Font Family */

/* For Poppins Medium */
.font-Poppins-medium-18 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  font-display: swap;
}

.font-Poppins-medium-16 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
}

.font-Poppins-medium-14 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
}

.font-Poppins-medium-13 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 13px;
}

.font-Poppins-medium-12 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
}

.font-Poppins-bold-14 {
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
}

/* For Poppins Regular */
.font-Poppins-regular-16 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
}

.font-Poppins-regular-14 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
}

.font-Poppins-regular-13 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 13px;
}

.font-Poppins-regular-12 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
}

.font-Poppins-regular-11 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 11px;
}

/* For Poppins SemiBold */

.font-Poppins-semiBold-36 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 36px;
}

.font-Poppins-semiBold-32 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 32px;
}

.font-Poppins-semiBold-28 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
}

.font-Poppins-semiBold-26 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
}

.font-Poppins-semiBold-24 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
}

.font-Poppins-semiBold-22 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
}

.font-Poppins-semiBold-20 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 20px;
}

.font-Poppins-semiBold-18 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
}

.font-Poppins-semiBold-16 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
}

.font-Poppins-semiBold-15 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
}

.font-Poppins-semiBold-14 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
}

.font-Poppins-semiBold-13 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 13px;
}

.font-Poppins-semiBold-12 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
}

.font-Poppins-semiBold-11 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 11px;
}

.font-Poppins-semiBold-10 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 10px;
}

.font-Poppins-semiBold-9 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 9px;
}

.font-Poppins-semiBold-7 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 7px;
}

/* End Font Family */

.app {
  background-color: darken($base-white, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

// Start CSS For Editor
.popupset {
  .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
    background-color: $base-white !important;
  }

  .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title.dx-has-close-button {
    background: $base-accent 0% 0% no-repeat padding-box;
  }
}

.bgPopup {
  // z-index: 9999 !important;
  .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
    background-color: $base-white !important;
  }

  .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title.dx-has-close-button {
    background: $base-accent 0% 0% no-repeat padding-box;
  }
}

.editorPage {
  background: $base-white 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 1px solid $base-whitesmoke;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 7px 7px 5px $base-blackbase;
  margin-right: 0.5em;
}

.editorHeader {
  padding-bottom: 0.5em;
  color: $base-accent;
  font-size: 14px;
  border-bottom: 1px solid #b2c7d1;
  margin-bottom: 1em;

  .lblSize {
    font-size: 16px !important;
  }
}

.dx-popup-content {
  padding: 15px 0px 0px 0px !important;
}

.dx-popup-title {
  background-color: $base-accent !important;
}

.dx-popup-title.dx-toolbar {
  padding: 6px 15px !important;
}

// End CSS For Editor

// Start CSS For Editor Buttons
.btnGroupSaveAndCancel {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  border-top: 1px solid $base-grid-footer-border;
  padding-top: 1em;
  padding-bottom: 1em;
}

.LoadProposalbtnGroupSaveAndCancel {
  display: flex;
  justify-content: center;
  // margin-top: 1em;
  border-top: 1px solid $base-grid-footer-border;
  padding-top: 1em;
  padding-bottom: 1em;
}

.btnSave {
  @extend .font-Poppins-regular-14;
  background: transparent
    linear-gradient(
      37deg,
      $base-dark-green-button 0%,
      $base-dark-green-button 100%
    )
    0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1;
  margin-right: 1em;
  color: $base-white;
}

.btnRecall {
  @extend .font-Poppins-regular-14;
  background: #f96b02 !important; //transparent linear-gradient(37deg, $base-grey 0%, $base-bg-button-skyblue 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  //opacity: 1;
  margin-right: 1em;
  color: $base-white;
  border-color: none !important;
}

.btnMultipleDelete {
  @extend .font-Poppins-regular-14;
  background: $base-accent !important; //transparent linear-gradient(37deg, $base-grey 0%, $base-bg-button-skyblue 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  //opacity: 1;
  margin-right: 5px;
  color: $base-white;
  border-color: none !important;
}

.btnSaveAndNotify {
  @extend .font-Poppins-regular-14;
  background: $base-accent !important;
  border-radius: 10px !important;
  opacity: 1;
  margin-right: 1em;
  color: $base-white;
}

.btnDelete {
  @extend .font-Poppins-regular-14;
  background: #737373 !important;
  border-radius: 10px !important;
  opacity: 1;
  margin-right: 1em;
  color: $base-white;
}

.btnDeleteProject {
  @extend .font-Poppins-regular-14;
  background: #c90f09 !important;
  border-radius: 10px !important;
  opacity: 1;
  margin-right: 1em;
  color: $base-white;
}

.btnAdditionalAction {
  @extend .font-Poppins-regular-14;
  background: $base-accent 0% 0% no-repeat padding-box !important;
  border: none !important;
  border-radius: 10px !important;
  opacity: 1;
  margin-right: 1em;
  color: $base-white;
}

.btnAdditionalAction:hover,
.btnAdditionalAction:focus {
  background-color: $base-accent !important;
  border: none !important;
}

.dx-button-mode-contained.dx-button-success.dx-state-focused {
  border: 1px solid $base-dark-green-button !important;
  box-shadow: 0 1px 3px rgba(0, 184, 123, 0.4);
}

.btnCancel {
  @extend .font-Poppins-regular-14;
  background: transparent
    linear-gradient(37deg, $base-dark-red 0%, $base-dark-red 100%) 0% 0%
    no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1;
  color: $base-white;
}

.btnExportForDelete {
  @extend .font-Poppins-regular-14;
  background: #2379b9 !important;
  border-radius: 10px !important;
  opacity: 1;
  color: $base-white;
  margin-right: 5px;
}

.dx-button-mode-contained.dx-button-danger.dx-state-focused {
  border: 1px solid $base-dark-red !important;
  box-shadow: 0 1px 3px rgba(185, 65, 65, 0.4);
}

// End CSS For Editor Buttons

// Start CSS For Conflict Popup
.btnReload {
  @extend .font-Poppins-regular-14;
  background: transparent
    linear-gradient(
      37deg,
      $base-dark-green-button 0%,
      $base-dark-green-button 100%
    )
    0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1;
  color: $base-white;
}

.setButtonPosition {
  flex-flow: row wrap;
}

// End CSS For Conflict Popup

// Start CSS-For List
.gridList {
  max-height: 80vh;
  font-size: 13.57px !important;
  font-weight: bold;
  // padding: 1.4em;
}

@media (max-width: 767px) {
  .gridList {
    max-height: 64vh;

    .dx-widget.dx-datagrid-pager.dx-pager {
      padding: 8px !important;
    }

    .dx-info {
      font-size: 10px !important;
    }
  }

  .treeList {
    max-height: 69vh !important;

    .dx-widget.dx-datagrid-pager.dx-pager {
      padding: 8px !important;
    }

    .dx-info {
      font-size: 10px !important;
    }
  }
}

.dx-datagrid-header-panel,
.dx-widget.dx-datagrid-pager.dx-pager {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  // margin-top: 0.5em;
}

.dx-widget.dx-datagrid-pager.dx-pager {
  @extend .font-Poppins-medium-13;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.dx-datagrid-header-panel {
  background-color: $base-bg-skyblue !important;

  .dx-toolbar {
    background-color: $base-bg-skyblue !important;
  }
}

.dx-datagrid-headers {
  border-radius: 6px 6px 0px 0px !important;

  .dx-datagrid-content {
    border-radius: 6px 6px 0px 0px !important;
  }
}

.dx-calendar-views-wrapper .dx-state-hover td {
  &:not([role="columnheader"]) {
    background-color: $base-white !important;
  }
}

// .dx-state-hover td {
//   &:not([role="columnheader"]) &:not([role="tabpanel"]) {
//     // background: rgba(102, 235, 191, 0.1) 0% 0% no-repeat padding-box !important;
//   }
// }

.dx-datagrid-total-footer {
  border: none !important;
}

//for Group Row
.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
  background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
}

//only for Tab Panel
.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
    .dx-row-removed
  ):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused) {
  background: rgba(102, 235, 191, 0.1) 0% 0% no-repeat padding-box !important;
  color: $base-grid-forecolor !important;
}

//only for tabpanel

.dx-selection td {
  &:not([role="columnheader"]) {
    background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
  }
}

.dx-row.dx-data-row.dx-column-lines.dx-selection {
  &:not([role="columnheader"]) {
    background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
  }
}

.dx-row.dx-data-row.dx-column-lines.dx-state-hover {
  background: rgba(102, 235, 191, 0.1) 0% 0% no-repeat padding-box !important;
}

.dx-row-focused td {
  background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
  color: $base-grid-forecolor !important;
}

td.dx-command-edit.dx-command-edit-with-icons {
  a.dx-link.dx-icon-edit.dx-link-icon,
  a.dx-link.dx-icon-exportselected.dx-link-icon,
  a.dx-link.dx-icon-print.dx-link-icon,
  a.dx-link.dx-icon-search.dx-link-icon,
  a.dx-link.dx-icon-money.dx-link-icon,
  a.dx-link.dx-icon-event.dx-link-icon,
  a.dx-link.dx-icon-card.dx-link-icon,
  a.dx-link.dx-icon-more.dx-link-icon,
  a.dx-link.dx-icon-copy.dx-link-icon,
  a.dx-link.dx-icon-save.dx-link-icon,
  a.dx-link.dx-icon-check.dx-link-icon,
  a.dx-link.dx-icon-changeOrder.dx-link-icon,
  a.dx-link.dx-icon-ViewSessionLog.dx-link-icon {
    color: $base-accent !important;
    background-color: transparent !important;
  }

  a.dx-link.dx-icon-trash.dx-link-icon,
  a.dx-link.dx-icon-search.dx-link-icon {
    background-color: transparent !important;
  }

  a.dx-link.dx-icon-todo.dx-link-icon {
    background-color: transparent !important;
    color: $base-green !important;
  }
}

td.dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled {
  a.dx-link.dx-icon-edit.dx-link-icon,
  a.dx-link.dx-icon-exportselected.dx-link-icon,
  a.dx-link.dx-icon-print.dx-link-icon,
  a.dx-link.dx-icon-money.dx-link-icon,
  a.dx-link.dx-icon-event.dx-link-icon,
  a.dx-link.dx-icon-card.dx-link-icon,
  a.dx-link.dx-icon-more.dx-link-icon,
  a.dx-link.dx-icon-copy.dx-link-icon,
  a.dx-link.dx-icon-save.dx-link-icon,
  a.dx-link.dx-icon-check.dx-link-icon,
  a.dx-link.dx-icon-History.dx-link-icon,
  a.dx-link.dx-icon-validateInsurance.dx-link-icon,
  a.dx-link.dx-icon-CustomPreview.dx-link-icon,
  a.dx-link.dx-icon-changeOrder.dx-link-icon,
  a.dx-link.dx-icon-ViewSessionLog.dx-link-icon {
    background-color: transparent !important;
    color: $base-accent !important;
  }

  a.dx-link.dx-icon-trash.dx-link-icon {
    background-color: transparent !important;
  }

  a.dx-link.dx-icon-todo.dx-link-icon {
    background-color: transparent !important;
    color: $base-green !important;
  }
}

.dx-datagrid .dx-row-lines > td {
  // border-bottom: 2px solid $base-light-editor !important;
  padding: 8px;
  padding-top: 10px;
}

.dx-datagrid-pager {
  border-top: none !important;
}

.gridList .dx-datagrid {
  border-radius: 0 0 8px 8px;
}

.zeroMargin {
  margin: 0.4em 0px !important;
  height: 2px !important;
  background: rgb(230, 238, 242) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.gridList tr.dx-row.dx-data-row.dx-row-lines {
  @extend .font-Poppins-medium-13;
  text-align: left;
  letter-spacing: 0px;
  color: $base-grid-forecolor;
  opacity: 1;
}

.dx-datagrid-rowsview .dx-row {
  border-bottom: 2px solid #e3e3e3;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td {
  border: none !important;
}

.gridList tr.dx-row.dx-column-lines.dx-header-row {
  background: transparent
    linear-gradient(90deg, $base-lightaccent 0%, $base-accent 100%) 0% 0%
    no-repeat;
  color: $base-white;
}

.dx-datagrid-search-panel.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 2px solid rgba(242, 242, 242, 1);
  border-radius: 11px;
  opacity: 1;
  margin: 0 0 0 2px !important;
}

.ListHeader {
  .dx-toolbar-items-container {
    background-color: $base-bg-skyblue !important;
  }
}

.headerText {
  color: $base-text-color;
  font-size: 24px;
  font-weight: bold;
  padding-top: 2px;
}

.headerTextForSmallScreen {
  color: $base-text-color;
  font-size: 16px;
  font-weight: bold;
  padding-top: 2px;
  padding-right: 20px !important;
}

.headerGridItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 0.5em;

  .dx-toolbar .dx-toolbar-after {
    padding-left: 0px !important;
  }
}

.headerGridBtns {
  // display: flex;
  border-radius: 8px !important;

  .dx-item-content.dx-list-item-content {
    .dx-toolbar-item-auto-hide {
      .dx-item-content.dx-toolbar-item-content {
        display: grid !important;

        .ListHeaderBtn {
          margin: 5px 3px !important;
          width: 100%;
          // padding: 0 0.5em; // this is disturb the button alignment
          padding: 0em;
        }
      }
    }
  }
}

.headerGridBtnGapManage {
  padding-left: 2px !important;

  .dx-toolbar-item-auto-hide {
    padding-top: 0px !important;
  }
}

.AddNewButtonInMenu {
  .dx-toolbar-item-auto-hide {
    padding: 0px 11px !important;
  }
}

.btnHeaderRefresh {
  border-radius: 8px !important;
  background: $base-bg-button-skyblue !important;
  color: $base-dark-menu !important;
}

.btnExport {
  background: $base-bg-button-skyblue !important;
}

.btnResetLayout {
  background: $base-bg-button-skyblue !important;
}

.btnHeaderAddNew {
  background: $base-addnew-button !important;
}

.btnBorder {
  border: 1px solid $base-grey !important;
}

.btnHeaderAddNewCampaign {
  background: $base-addnew-button !important;
}

.btnHeaderRemoveFromCampaign {
  background: $base-dark-red 0% 0% no-repeat padding-box !important;
}
.btnHeaderWebsiteinfo {
  background: #046da2 0% 0% no-repeat padding-box !important;
}

.btnHeaderMergeAccount {
  background: $base-accent 0% 0% no-repeat padding-box !important;
}

.btnHeaderAddBid {
  // border-radius: 11px 0 0 11px !important;
  background: #8cc63f 0% 0% no-repeat padding-box !important;
}

.btnHeaderEditMilestones {
  background: $base-yellow 0% 0% no-repeat padding-box !important;
}

.btnHeaderEnterBill {
  background: #00a99d 0% 0% no-repeat padding-box !important;
}

.btnHeaderEnterReceipt {
  background: #22b573 0% 0% no-repeat padding-box !important;
}

.btnHeaderAddNotes {
  background: #f58042 0% 0% no-repeat padding-box !important;
}

.btnHeaderPayBill {
  background: #009245 0% 0% no-repeat padding-box !important;
}

.btnHeaderFiles {
  background: #00a99d 0% 0% no-repeat padding-box !important;
}

.btnHeaderAddCutomerPayment {
  background: rgba(0, 146, 69, 1) 0% 0% no-repeat padding-box !important;
}

.btnHeaderAddMultipleExpenses {
  background: #006837 0% 0% no-repeat padding-box !important;
}

.BtnHeaderTemplate {
  background: #00a99d 0% 0% no-repeat padding-box !important;
}

.BtnHeaderAdjustment {
  background: #00a99d 0% 0% no-repeat padding-box !important;
}

.BtnHeaderApproveCommission {
  background: #006837 0% 0% no-repeat padding-box !important;
}

.HeaderBtnsubcontractorSchedule {
  background: $base-lightaccent 0% 0% no-repeat padding-box !important;
  color: $base-white !important;
}

.HeaderBtnTaskNotes {
  background: $base-yellow 0% 0% no-repeat padding-box !important;
}

.HeaderBtnUpdateJobSchedule {
  background: $base-dark-green-button 0% 0% no-repeat padding-box !important;
}

.HeaderBtnCostControl {
  background: rgba(0, 146, 69, 1) 0% 0% no-repeat padding-box !important;
}

.HeaderBtnAcceptBid {
  background: #126726 0% 0% no-repeat padding-box !important;
}

.HeaderBtnDeleteBid {
  background: $base-dark-red 0% 0% no-repeat padding-box !important;
}

.HeaderBtnCustomerStatement {
  background: #6f2c05 0% 0% no-repeat padding-box !important;
}

.HeaderBtnSaveAsNewTemplate {
  background: #f4845f 0% 0% no-repeat padding-box !important;
}

.HeaderBtnExportJobTemplate {
  background: #004570 0% 0% no-repeat padding-box !important;
}
.btnHeaderExportToExcel {
  background: transparent
    linear-gradient(90deg, $base-accent 0%, $base-accent 100%) 0% 0% no-repeat
    padding-box;
  i.dx-icon.dx-icon-export {
    color: $base-accent !important;
  }
}

.ListHeaderBtn {
  @extend .font-Poppins-medium-13;
  background: transparent
    linear-gradient(90deg, $base-accent 0%, $base-accent 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 0 !important;
  color: $base-white !important;
  border-radius: 8px !important;
  margin: 0px 3px;

  i.dx-icon.dx-icon-plus,
  i.dx-icon.dx-icon-event,
  i.dx-icon.dx-icon-detailslayout,
  i.dx-icon.dx-icon-money,
  i.dx-icon.dx-icon-textdocument,
  i.dx-icon.dx-icon-file,
  i.dx-icon.dx-icon-globe,
  i.dx-icon.dx-icon-spindown,
  i.dx-icon.dx-icon-spindown.dx-icon-right,
  i.dx-icon.dx-icon-export,
  i.dx-icon.dx-icon-revert,
  i.dx-icon.dx-icon-trash,
  i.dx-icon.dx-icon-edit,
  i.dx-icon.dx-icon-back,
  i.dx-icon.dx-icon-search,
  i.dx-icon.dx-icon-unselectall,
  i.dx-icon.dx-icon-description,
  i.dx-icon.dx-icon-exportselected,
  i.dx-icon.dx-icon-check,
  i.dx-icon.dx-icon-import,
  i.dx-icon.dx-icon-History,
  i.dx-icon.dx-icon-validateInsurance,
  i.dx-icon.dx-icon-menu,
  i.dx-icon.dx-icon-smalliconslayout,
  i.dx-link.dx-icon-CustomPreview,
  i.dx-link.dx-icon-changeOrder,
  a.dx-link.dx-icon-ViewSessionLog.dx-link-icon {
    color: $base-white !important;
  }

  i.dx-icon.dx-icon-refresh {
    color: $base-icon-color !important;
  }
  i.dx-icon.dx-icon-export {
    color: $base-accent !important;
  }

  .dx-button-text {
    color: $base-white !important;
  }
}

.ItemMasterList{
  @extend .ListHeaderBtn;
  i.dx-icon.dx-icon-export {
    color: $base-white !important;
  }
}

i.dx-icon.dx-icon-column-chooser {
  height: 14px !important;
  width: 14px !important;
  line-height: 15px !important;
}

// End CSS-For List

.dx-fieldset {
  margin: 20px 10px !important;
}

span.dx-header-filter.dx-header-filter-empty {
  color: $base-whitesmoke !important;
  font-size: 10.41px !important;
}

.textFontColor {
  color: $base-accent;
}
.CheckBox_textFontColor {
  color: $base-accent;
  font-size: 14px;
  font-weight: 600;
}

.roundedCorner {
  border-radius: 8px;
}

.floatRight {
  float: right !important;
}

.fontBold {
  font-weight: bold !important;
}

.txtUnderlineStyle {
  text-decoration: underline;
}

.ml-5 {
  margin-left: 0.5em;
}

.mr-5 {
  margin-right: 0.5em;
}

i.dx-icon.dx-icon-close {
  color: #bfc8cf;
}

.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.dx-button-has-icon.dx-closebutton {
  .dx-button-content {
    padding: 4px !important;
  }
}

a.dx-link.dx-icon-trash.dx-link-icon {
  color: $base-dark-red !important;
}

i.dx-icon.dx-icon-close {
  width: 15px !important;
  height: 15px !important;
  background-size: 15px 15px !important;
  font-size: 15px !important;
  line-height: 16px !important;
}

.dx-toolbar-label > div {
  @extend .font-Poppins-medium-18;
  color: $base-popup-title;
}

.elementIndent {
  .dx-field {
    .dx-field-label {
      padding-left: 1em;
    }
  }
}

.hrMargin {
  margin: 1.5em 0px !important;
  height: 2px !important;
  background: rgb(230, 238, 242) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.HeadingLead {
  color: $base-white !important;
  font-weight: bold;
}

.contactInfoElementSpace {
  padding-right: 0.5em !important;

  .dx-field-label {
    width: 25% !important;
  }

  .dx-field-value {
    width: 75% !important;
  }
}

.dx-field {
  margin-bottom: 5px !important;
}

.dx-command-select {
  width: 35px !important;
  min-width: 35px !important;
}

#emailListGrid,
#textListGrid,
#postalListGrid {
  .dx-selection td {
    &:not([role="columnheader"]) {
      background: transparent 0% 0% no-repeat padding-box !important;
      border: none;
    }
  }
}

// td.dx-cell-focus-disabled {
//   user-select: text;
//   -moz-user-select: text;
//   -webkit-user-select: text;
//   -ms-user-select: text;
// }

.dx-pager .dx-pages {
  float: left !important;

  .dx-page-indexes {
    display: table-column !important;

    .dx-page .dx-selection {
      display: none !important;
    }
  }
}

.webviewer {
  height: 100vh;
  flex: 1;
  -webkit-box-shadow: 1px 1px 10px $base-lemon-grass;
  box-shadow: 1px 1px 10px $base-lemon-grass;
}

.dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0 5px 0 0px !important;
}

.dxrd-print-dialog .dx-popup-content {
  margin: 15px 20px 20px !important;
}

.ace_scroller {
  line-height: unset !important;
}

// ______________________ New scss_________________________

// for base accent text color

.accent-color {
  color: $base-accent;
}

.smokey-grey-color {
  color: $base-smokey-grey;
}

.lightaccent-color {
  color: $base-lightaccent;
}

.StatusColor {
  color: $base-accent;
}

.red-color {
  color: $base-red;
}

.yellow-color {
  color: $base-scope-bg;
}

.grey-color {
  color: $base-grey;
}

// .HeaderBtnCorrelatedItems{
//   background: #8CC63F 0% 0% no-repeat padding-box !important;
// }

.HeaderBtnCredit {
  background: #4f7e0f 0% 0% no-repeat padding-box !important;
}

.HeaderBtnAddBid,
.HeaderBtnCorrelatedItems {
  background: #8cc63f 0% 0% no-repeat padding-box !important;
}

.HeaderBtnMilestones {
  background: #f58042 0% 0% no-repeat padding-box !important;
}

.HeaderBtnAddNotes,
.HeaderBtnImportItems {
  background: #f7931e 0% 0% no-repeat padding-box !important;
}

.HeaderBtnEnterBill,
.HeaderBtnItemHistory {
  background: #00a99d 0% 0% no-repeat padding-box !important;
}

.HeaderBtnExportItem {
  background: #147011 0% 0% no-repeat padding-box !important;
}

.HeaderBtnEnterReceipt,
.HeaderBtnEditCost {
  background: #22b573 0% 0% no-repeat padding-box !important;
}

.HeaderBtnEnterPayBill {
  background: #00a99d 0% 0% no-repeat padding-box !important;
}

.HeaderBtnEnterCreateInvoice {
  background: #29abe2 0% 0% no-repeat padding-box !important;
}

.HeaderBtnEnterCustomerPayments {
  background: #009245 0% 0% no-repeat padding-box !important;
}

.HeaderBtnCustomerStatement {
  background: #6f2c05 0% 0% no-repeat padding-box !important;
}

.HeaderBtnHoliday {
  background: #00488b 0% 0% no-repeat padding-box !important;
}

.CustomHeader {
  @extend .font-Poppins-semiBold-18;
  background-color: $base-accent;
  color: $base-white;
  padding: 0.6em;
  border-radius: 6px 6px 0px 0px;
}

.dx-texteditor-input {
  height: 32px !important;
}

.label-light-color {
  color: $base-label-light-grey;
}

.label-dark-color {
  color: $base-label-dark-grey !important;
}

.light-icon {
  .dx-icon {
    color: #ffffff !important;
  }
}

.gridList .dx-header-row > td {
  @extend .font-Poppins-semiBold-13;
  background: $base-accent !important; // //$base-light-gridheader;
  color: $base-menu !important;
  padding: 0.8em 0;

  .dx-datagrid-text-content {
    padding-left: 8px !important;
  }
}

.MasterDetailGrid .dx-header-row > td {
  @extend .font-Poppins-semiBold-13;
  background: $base-accent !important; // //$base-light-gridheader;
  color: $base-menu !important;
  padding: 0.8em 0;

  .dx-datagrid-text-content {
    padding-left: 8px !important;
  }
}

.gridList {
  .dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
    margin-right: 10px;
  }

  .dx-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview {
    border-top: none !important;
  }

  .dx-datagrid-summary-item {
    color: $base-accent !important;
  }

  .dx-datagrid-total-footer {
    border-top: 2px solid $base-accent !important;
    border-bottom: 2px solid $base-accent !important;
  }

  .dx-datagrid .dx-row-lines {
    td.dx-command-edit {
      padding: 0 !important;
      vertical-align: middle;

      a {
        border-radius: 6px;
        background-repeat: no-repeat;
        padding: 12px !important;
        background-position: center !important;
        background-size: 12px 12px !important;
      }

      // .dx-link .dx-icon-more .dx-link-icon {
      //   background-color: $base-bg-button-skyblue !important;
      // }

      a.dx-link.dx-icon-card.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACzklEQVR4nO2VXUhTYRjHV0SgmKGm93VVEXgRQZIW0e2mZM1uFN3Ox850c27qrE1mZ+sTSoWSlTem9m1Ts22uuc1TW2nlsnChhqAOuigyLWiDIp54ztQmOj/6uOvAj/d9n/M8///7xTkCwRqesVfSXYjgbz8jQ4X7PBzRYzCXvatuUL13ushHrweKsv5YeHBQetDhojmDWfUxTcxCvNDEsyWXhdJazZTdTftfvpAcBRCsW7UoQM36gWdknsNN+5UXNDNJh428aJZSB+abJTyZCj0fS8wxQgFb8cXqpIcG+gg59B7YEFM4EBBvfOolFJicz1Z+3ZQdEY6fpb5FAanZLKTlnIT6ZsWCdwkiE4i0VWGLXTba308YgkFx3ALxiZH8rVYnPXZIfeJbdGE0pkYV7KX0kEHrwdioWjIHQQ3UQs15g8nhwt3U2YrpWEXxQhOk5rKgqVODulbDn8FyuaiFmvMG46MFO5weKmCxycJ3HzDhNpts4nYXM3jHygy3WZkwgn2MxYpjDdaiBmqh5qJzaGqTT2bKdeDzEdq5lRWf0cwg0TNaKo41WIsaMQ/6v0Hm/y0S/OtbFAyK4/zPJbTPSzRcblWEEexzHKnjOMKhv1QGnXbZdGRMVo2PFG5fk4HvMXklV6v9jjkrsb9YB90u6u2aDNy9lCNFxMJmkREShRGSRSwwF9uhxeFfQLq0Hrdr4rcMEoS/vrRJqzHAvXJ7yKEuBx3qsNOhThs13m6V+Tts9Jv73XQIwX6Pm/qMBskiIy+M4ApSYjBvMDfTWIkps9RdU/Kt4rwGTl8thQyZno9hi+OSc+Vw5LgWapuUsO1YTcTA3y9Rdz2kPzhcVMjupMLL4fKQP9Agp7IKqFPli8B49ISa78k/8fc3z6CFPUz1iphvRf5q6UXVS94cjEcbtFqYaUEgINmJJquh74n0RqddNtVqYVbkejsz5fWS3E/LDr/P5QORHQAAAABJRU5ErkJggg==");
      }

      .dx-icon-card::before {
        content: none !important;
      }

      a.dx-link.dx-icon-edit.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAABHNCSVQICAgIfAhkiAAAAZFJREFUWEft1j1WwkAQB/CZFGjHHsEb6A2EQh8tJxBvkAZasfPFQm4g3oAy2hhvIDfZZxd8j3ETAWHd3WSXbEhB2nz98s/sziA06MAGWeCI0f0N78mwq+HFMsCbgOCDv0UzU1l4xWQQCvBdAFiGQKRbHj9Oa09GhqwBJpC3ZNrXo0Qsj0tVCjqQNwzrhIxOWonAnKtBQZfHD9n5zVEp5vfXwBOm332eTLgJpEqnMoxUI5+YLro6ECG8fMXRQE6sEoymWJUgHSRfbfu2A92qWT13BwSnrZDH0djL0lZA5ohBSLTMNre2DCr6cOdklJB00cnrpDcaE8Hd394C96ZENtcVaVXnzZDhgAif1/eZamTvAvYFsS5gnxArjG9IaUwdkFKYuiCFmDohRkzdEC3mEBAl5lAQJUaa0OZiFFht8e47a9ld/l9v2saIppdPY6znH1KYDAFOAYgLcejSa8omom2UpkHapunZQgqT2X6gb4h6NYn6EAPg2c6XEXD+Gk1cvtbmHufhyuYlZa89YnRJNSqZHyc3XjNdBrgQAAAAAElFTkSuQmCC");
      }

      .dx-icon-edit::before {
        content: none !important;
      }

      a.dx-link.dx-icon-creditUsed.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6ElEQVR4nN2VO2hUURCGv/jAQsEXWihoIwoWaRa0MQhxs3dmV4goC1pY2ARBJMa9M/cKwhLsbCRFEGtTiY0gYiOICD4QxcJCwVdWESsLwVbZs0aM7t292Swp8sOBy2HufP+cM+ccWFIdrK9C/BLin1A7DQz0L3kxWY/6A9RvENkQao8Qf4gmw/0BiN1B7cof1/X6CsSqqD1H7T3q05RjQc6uWXhytSOoPQ1J28JrexGPUb+P+DfEbwZ4YWx1ToDfQvx4rthKupGynUTsHurvqKSFPBU0KNo2FqrITqD+MgfAv6LJGXqR+BskHuwCSHcj/gL1y5kxlbQQukrsNZFd/MvcNOrnuzsZHd8Qftb4WIbTD6hPUvJ9wXWzlVuAU6jPkEvl2n7EvzBS25qx3p+J/Cji51C73gLHg6i/ygdoOa2jdrttC0Y2hNgz1H92HtZAXbOvC7XvqD0hiouM1Nb+EzFAORlF7CNqU1SrK/83mRxAfTa7iqaL5pKIP0b8B2Jvw2aW0l3z9kztLuJXM3N0BMxfmj2hc0I72xSliU2/q10X5g4nOxYHmNOhdHO4rwLIZ1C/Fr7lwhb6AphTZWI7YmOojVOu7aSdFgXIo66AfozlXIE1wmGhR4Unt/NB0xDQu/vZ8LwuhX4Bj0UXfgEUrbEAAAAASUVORK5CYII=");
      }

      .dx-icon-creditUsed::before {
        content: none !important;
      }
      a.dx-link.dx-icon-EditFollowupIcon.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXUlEQVR4nO2WPUsDQRCGXyPGyo9fIYKdgqBYqdxMGq3OnyAWBiGZudhdJ1oLFlr40QliKht7bdLaiI1NtPTjD0TuTs9N7kI4swcpfGGamb19dt5ZjgVcvwjWA7C+gLVlJ6QJkv1w71hBwhpA2yPYO5bVTrQDpK8mqJVrxEokLIn/Qblaxx25zIPHoIFsiAcKxDasEwelylT+oIRys072wPIAx5vPD+R428aP9Q2ks3asIz0CMATfL8CpTGNNx8By91uXRv8gMiCspyD5hOMtgsvjYL2P1smuHet8vwCSMwP+AZIFrNYmQLqVfvqsct1hkJ4nu5V3kDfT3SYrEA3mcgMujxogaUbF2lJmEOlxF0i9/WHyp8eJPIXfre9MgvQZpeoyWC+N+VxjbnMkpX2/GMF+OusJOowOWN0Ay0nkiq5816/SIf0ovGVyG4JJH8OrbR0SgepguQg7C2zsoS+vcQw+aYJljgAAAABJRU5ErkJggg==");
      }

      .dx-icon-EditFollowupIcon::before {
        content: none !important;
      }

      a.dx-link.dx-icon-search.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYElEQVR4nL2UzytEURTHv+M3RcnGVilbJZHUkLxzeiMb3sZWTbOZhd45z+yubCnZyEJjZxazsJCVjSyslT+ASEqxIz8W9G6IevPeU/huv+d+zrnfe7rAn4v9QZAugXQLpGvgYBZcbE4PmCh1gWUPJE9gPQDrJkgrILkB6TnIH0uGTGs7WE/BcoRJv+eblzUtYF0G6WMyjHUdLCeYMm21a8SA9QLeQmt0QXiY5B4UUGwzzzSB5QqOzNXotDgK0gdkTUP82Agn3wbpRrRJfg6k14mQUCyr9gEi5Uo/WF7AxQ4kiWQXpCvRpjF1ILkEaSEW4kq3zdLR8dpFrHmw3oK0L9IfyDeCZB8kh0lDZ+wmhzD257+sQQZOMALWY7C+gqQKz6tPAyvY4O12yxlI7sD6DJYyOBi2EbDspIHBFrn+EByZsbuVK3V+ek6p9z3PSjpYnD5g4WThLX4BVv3Zr/BfegNlf3WKjHX+FwAAAABJRU5ErkJggg==");
      }

      .dx-icon-search::before {
        content: none !important;
      }

      a.dx-link.dx-icon-trash.dx-link-icon {
        background-color: $base-bg-button-pink !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAZCAYAAAA14t7uAAAABHNCSVQICAgIfAhkiAAAAK5JREFUSEtjZMABjquoHABK2eOSh4o7Wt65A1KHARiBBvwnoJkc6UbaGQxyDtDVDUCqHogbgV4DsUkGMJ8D9TOCNIOJ4WMwuvfw+Y6koBg1GJ7cRoNiNCgwC6HRVIGcKhxABQ9y9XNcQ0OB4c8fBQYWlgeWN248gBXcJBVCpJT2A2Tw//8PGBgZ4d4jxcVAtbBgQ6qaIOF2ASjJT6Jh6MrhdSbYdKQIANtKFkCLTACtDUlHzwLWTQAAAABJRU5ErkJggg==");
      }

      .dx-icon-trash::before {
        content: none !important;
      }

      a.dx-link.dx-icon-print.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEKUlEQVR4nO3dIY5dZQCG4Y/JFRUIZBWpRLAESEoVK0BCt0AIGoliDbQ1TdgEXUBFU4WoIFUjkSMRUzSq80/yPk9yzc1Nzmfum3OO+T/Ztz8v7um2z0+P4Ij3256dHnHS5fSAe+CHbY9Pj+CIV4sH4Or0AOAcAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYAwAYCwy+kB98CP2z47PYIj/jk94DQB2N6cHgCneASAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAsP+OB3+67adtj7Z9emoM8NHdbHu37bdtzy7bvtr2+9FJwF15sO3L3f7n311te3J2D3DIE+8AIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIEwAIOzy/z/56P7adn16BBzwcNsXJwfchwD8uu3F6RFwwPfbnp8c4BEAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwgQAwu7D8eCnPdz24PQIjrjZdn16xEkCsL3c9vj0CI54te2b0yNO8ggAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYQIAYVeLn44KYddX215se316CXCnXm97cdntGelfb/tu26MDQ94euCbcB2+3/XLgun9v+2PbzeXDFze7vRMA7s6bD59jvASEMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAMAGAsMvpAffA821/nh7BEe9PDzjtX1PbKKucQbtlAAAAAElFTkSuQmCC");
      }

      .dx-icon-print::before {
        content: none !important;
      }

      a.dx-link.dx-icon-export.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1klEQVR4nO2WLQ7CQBCFnwLBuchMHQbLDYrpG80NuAGKkGAQSEhBgCUcAAw/HiS4EtLakm2bNpTwkrH7Zd/OvB1AuYZalLuEWzipCESTygTKI/2D9Nuty6TagsT2kKBfPkj5SM6cQP1mFaAIyiXaQcsd5FkHwiGEIyhnEIZQ20F5hPIM4Q1iz5SICuObuYDULgXzcOoGEl6rAXnsxY/LOcRWiW0HiJ0S2+4fIBmsy9sMYotszZAHJDZGd9Aod2CV/g9FUKr+P2x9dgYte916L4CFQNy4cF4fomoV/JHjAQAAAABJRU5ErkJggg==");
      }

      .dx-icon-export::before {
        content: none !important;
      }

      a.dx-link.dx-icon-money.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB60lEQVR4nN1USy9DURA+6rHwR/yAxs7CI3FPWVRoK/Zix0LPvRWP1EJEsUFQGgtVtbFo0y6E7kpYFGFBq8pGNIggrUQkHLnT1KtnesuyXzLJPZNz5rsz38wQUtww2yuIQW4nlHkJZVFC5XTG1G/mJZLVAnf+hUbWRiSWIFTmeU2Sz4mktP7hr82lhLJJzcA0h2iC2O06bYI8wYc9m2B5iBzaZUEeV/dM841IDKxpaInrDIqYxCC3IKWxV0A9BY9MI27+9v7Ov2M9fIxlcSEWHrpF/PfHl0kIOu3f5h1jq/zuKQ3nGussRmLOJZDkNYzg+eUVAlYa++E85QvD2eoKIIIzj0jcM4xgP34FAXsXA3jtfxiLighS2IM6m/Mzi6OLa24Z9WgRpP5EQKjMqzrHeWDv5FPk5VAk30w8iTSIFTJQ9bYFfvOQAhJpwIXdOxVl4BVd7p7zQTBV2KxvPrgLPuYKYhmsCDKwWrABU3H7mOYNfQvQmonkPfjaRtxIBoopl0DfVU4oi4sezPh3+G9sHZzxsmabqEUT+IZVtyJS+1rFyUOHcTDD4BIvwdpVYkZx8C+xJ/697CQ2RjShrlzKxgvpKPLTHIWt6yzUrUjFmvwaqrh2WTDoVeFlM+wWtbdhGGEgTzM+xQR3ihofBrWcWwCOfogAAAAASUVORK5CYII=");
      }

      .dx-icon-money::before {
        content: none !important;
      }

      a.dx-link.dx-icon-event.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA90lEQVR4nGNgGCiwbvdJ4Q17jxSu2nFMiBI1OMHGvYcrN+w7+n/D3qMVlKhBAI9cdgaP0jUM7iU/GTxK/2f2TP0P0gyiQXxsGEMNSK97yWqwWRjAvTQFWfOBPdlgzft35+C0AI+aJGwWNCArOrbb/f/m/bv+H93lhtMCnGpAZhGyQCk65X92k+N/xahUnBbgVIPNAtfy6QdwGcRAIgabhQ7OX7/dcP7Gnf9UwddvNwy8Bev3Hf0/Yck6ME0Me/BZcH40iNDBaBCdH/BUdO7GrWRqWXDh+l3M4vrWrVvs52/cWX3uxp2f5Bp8DqJ3FcgsDAtGAQMOAADP80C7WpoZQwAAAABJRU5ErkJggg==");
      }

      .dx-icon-event::before {
        content: none !important;
      }

      a.dx-link.dx-icon-todo.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAB9klEQVQ4jc2TPWiTYRDHf/fkfU2oROoiXa3QxS7WQkFBHORtxcHoqHQQRBfBgql08KMolrYJFlylaidXUaMJWBElg2hBhQ4dHCyitKItCTaJ78c55A1pQisRHLztnuN+d/e/e+B/M2nwBoavAztazF0mm7pcc8y/7+jgaIwtxd1/lf0rPs+L0XIddGRkO22VMgVrBOFKSxDlGtu8cdaiMTLjK9XRXLeTgp3FNzdRzgHBHxEqSSpbUxSth7huJ4BVH1IPYOlzAj0MrCByD7CbID7CGdR9QPTnM6CvFmgWuwdjXhHRPKrHgNK6WAnVozxN3cFYvRh9BDxBLG8jEKBdBGaKXDoDxkFZRVkF45BLZ3CS3UAHmHli7klyE++hJrZzYS/GvA1Jb4i5DhV7EN/M4ZsFrLJiPI8gNoOQWFe1iMp5cpN3mzuqQU6j3MIEL7G9NLmpH40Q+Qp8A+KITtM/PGA2gaTCtwhKCSfZ3dCJ8AX0UAgThEtVkB/9vAGkZguI2RPqN0MQ9GIYJJv+EMLWgJ7q+mfHlkgMtYMUEM42ai95xO8CAZF9GKkQBIvADTDHQduA5cZPu5klhtop24tAvDqazKE8Br0aFrvdGgig/+IpRKepbtqlfqyfcO2+SMugj/l37Nr/GmEn0IHwHZX7ePYJZseWfgNDDraCIbQuWQAAAABJRU5ErkJggg==");
      }

      .dx-icon-todo::before {
        content: none !important;
      }

      a.dx-link.dx-icon-todo.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2UlEQVR4nO2SsQ6CMBCG2WjF+ma6GQZnria+BqNGXNzb6+YrGB/JF8CkphUQBGOsRRPDn9zQDt93d7kgGPI3WSKbcclOIKOVdziIKAbBci6ZNuVVAg9wW4Ide4ODYDlINv0cLsfzjs4vXE4Wv4ODIgdAcgZF07fXIqL4NVyF2haGGiTJ2p2TzAluktjOC0GHBAwcQ+0Ev01A04agJoECbv6c4JUEya4ENSR4fzvDy3BJNy2Jqgl8nGKiyPaZhPu4czsJ0nV7XWTvBd4pQc/wSqJGiale4EO+kiuwpO8nVKbwBQAAAABJRU5ErkJggg==");
      }

      .dx-icon-todo::before {
        content: none !important;
      }

      a.dx-link.dx-icon-to.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAB9klEQVQ4jc2TPWiTYRDHf/fkfU2oROoiXa3QxS7WQkFBHORtxcHoqHQQRBfBgql08KMolrYJFlylaidXUaMJWBElg2hBhQ4dHCyitKItCTaJ78c55A1pQisRHLztnuN+d/e/e+B/M2nwBoavAztazF0mm7pcc8y/7+jgaIwtxd1/lf0rPs+L0XIddGRkO22VMgVrBOFKSxDlGtu8cdaiMTLjK9XRXLeTgp3FNzdRzgHBHxEqSSpbUxSth7huJ4BVH1IPYOlzAj0MrCByD7CbID7CGdR9QPTnM6CvFmgWuwdjXhHRPKrHgNK6WAnVozxN3cFYvRh9BDxBLG8jEKBdBGaKXDoDxkFZRVkF45BLZ3CS3UAHmHli7klyE++hJrZzYS/GvA1Jb4i5DhV7EN/M4ZsFrLJiPI8gNoOQWFe1iMp5cpN3mzuqQU6j3MIEL7G9NLmpH40Q+Qp8A+KITtM/PGA2gaTCtwhKCSfZ3dCJ8AX0UAgThEtVkB/9vAGkZguI2RPqN0MQ9GIYJJv+EMLWgJ7q+mfHlkgMtYMUEM42ai95xO8CAZF9GKkQBIvADTDHQduA5cZPu5klhtop24tAvDqazKE8Br0aFrvdGgig/+IpRKepbtqlfqyfcO2+SMugj/l37Nr/GmEn0IHwHZX7ePYJZseWfgNDDraCIbQuWQAAAABJRU5ErkJggg==");
      }

      .dx-icon-to::before {
        content: none !important;
      }

      a.dx-link.dx-icon-exportselected.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVR4nO2Wu0oDQRSGTxPUFIqCbyA+gHkALWTnD0kr2mkVFUwRc04SC11Now/gU0TxAhY+QEStLKyUdD6EiJHIXgjG7GUWd7cxAwcWdmb+b2b+mXOIkmi5UoYgLTus71RbrpQhJRcE6blxQyiPpSjOlz/EU4ZQskCKvzwAevaupHIcqK6Rkq4nRGo7gagQRTNLkCYp6XgP5HKg4HJ9ilS1QIo3CVK3Q8mDD8Cv4yiaWVJ879vZGXDoKayqSwS+JcUfgeMDISBNjc6DAEZlhiBXkUWHo2U5txMJwGjMEfg1BvE+QDe8o+uBQmM6RnHXjPD46etuvvbZoS6BHwlyTpBTUnxCkGc9E0ITwDbc0ERtysuqvTMDoLV1/QcJmgCW2/uT8BsZDG9Q3oj2GkIDwL7n7lWzVo292fieYmgAWI+MM8kToTxJUZNRYB6ABgCkROB3yu/O+4r7QYQmIWgAGLUdUnIcKj4IcaZXkCDCNUykIQEA8HY/KTmJaStlAIkwJ0YAMjqC3j83odIpSP4a/BkE0EkcQMlLAAAfJQ/A+/4AK5UJAt8luPo2LZrjwaZxIEy74IzDE06NaBWvB2Hi340URx6tqGcZAAAAAElFTkSuQmCC");
      }

      .dx-icon-exportselected::before {
        content: none !important;
      }

      a.dx-link.dx-icon-more.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2klEQVR4nGNgGAVDFKhHdehqRLRFaoc3yyKLG6fNZFWPbPHViGrzAbGR5bRiO+RAekB6wQKaUW0ZmpFtfzWj2v5rRLZ+0YrssAErDK1n04xqPQYSB+PItiMwwzSjW201Itu+QsX/akS1pzFoRrY9gyuG4E0gxSBXoIn/14ho8QYbFNm6GVWu9SkDiKDYoMi2JwwgZyF7TT282QoWPiDvaMIMiWo9DPMayPsgtXCvRbangMNJI6ZVRyOyNUIlukMGPbA1otp8QC5BD2xQxID0aEZ0aNMg/kcBXQAAN16MbUN6RdwAAAAASUVORK5CYII=");
      }

      .dx-icon-more::before {
        content: none !important;
      }

      a.dx-link.dx-icon-copy.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0UlEQVR4nGNgwAKErZJ4uSyTE7lsUtLwYW7LFCcGYgCnRbIZl1XKLS6r1Lt4sXXKL07r1FaiDCXKYsu0PE7r1Bs4FXDbJOtxWacewucqkAEwV3FZpSRwWafcx2kgyDBO65Sd+MKN0yq1EORVbqs0RyIMTLkPUkTIqzB1owZigtEwxAlGk819gmGDO/RQ1XFYplpxWqcuxq3QKvUmqEjCa1poKDOnVeorDpuUSEIWM4CKJVBJgr/4SnnJaZXymss+S4KggSAALpbwFftWyTH4DAMACrW/DPS4WBwAAAAASUVORK5CYII=");
      }

      .dx-icon-copy::before {
        content: none !important;
      }

      a.dx-link.dx-icon-save.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVR4nO1VMW4CQQwcKRVVkPgCv6AEYVNR3QsQNBQIxRZUEWXaAz4ApM8D6CLIK/KFSKRJUgSB9naDuOTg9qDMjWTppB17zvbaC+TIhGavCJJHkL6DdZdqpN9gDREEN34CJjjLJ1inIF3ZIPKQbO7ccp4Q9AseAtGfh+57FDmf5tpzkq7NRNaoDkvnBWzao0wCBiRNkH6A9BX1YRleAqx9V4J7sA7+mjyDZXPwbUgFrG+RiJeASZd0BZJtcpNlA75rxfzTso4JXAL6XwKsHa+BM5yGtLML2OAvpwfNmeH83KaMGfiVi47nIRe4ukSmsaTji3vAMomtjwTywq3r0F3DZfIeitnScUOQfoF0dv7BYZl7Pzj8azeZ4LXBbWrWOXCEPS2mJhFsmDQhAAAAAElFTkSuQmCC");
      }

      .dx-icon-save::before {
        content: none !important;
      }

      a.dx-link.dx-icon-check.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACj0lEQVR4nK2WTWgTQRTHH2ptENRD6a0fKMHSg0l70YPFL/RiexBU8KO7k7QS09KCCcZYTQyISvBivRR6yoe3nUk/YrMm3UrUKAShkVpEsWhDa6mCR4tY0qzMqlmTbrLZ1Ad/dmdZ5rfzf/PmLQANRG4CwsMbEoNboWiw+AWYyW5JLDZAF9eUG5vLECIOQMRUAkDi9GJ0CfxR77Mfre6pFYNbsIMlvA0Q4QFhoYQGpcmpGK4BWBwERAJgGq3PBzBcW08glekNpkRfYl6kEEA+HSB8GBhyrKgQ15IDIDwujaldiIwVAHDHDTKbPXj3qdgTSIl0JWDmaoEltwARb3FhqwwgKWCxUxK9zwP089XN12ILA9ybrNU/nWlxCROKFpnICWDx/TyLZUBSXhlJrssBhQAKtQMiB6Sxx7NJi0XbL40t7nVNfqbaaR1fWg8oDOTTabFI74jOR2aWRX5mWWxyxhbUARZtu4gCfIm0SFUewKNgEU28OWRUyoF2AFKwiOX2ASKWygDSvg+105oALYHKASCfrnkgtngn/C7bF3ydMbqFyP/NAYM7rpPZrHfivTgYm5MLDZGrueJRklwDaoDfR8XU26/iyw/fKjoq9Go5oLYc+XPYGV2CrWglU1sq3kUXHu4Ay3CVnHSiySJ/Ii36N1QHTHGL6myRpQeTcyJVg53/og6waOwHLH4FLAkBiwmweFodgBQs6sT7pcmojQUdrap7pFPviK7scUa/V18c7a7MIjPtEdgAiDBtt+OrZ4eSP7d2jaxJ4DPc5rrLkU/1Nj4Nh+Jb1AFQIhAxDT35KB6/93y10RY5//dxTV/4ZG3/o9P5L//b9DU0+pre8Fqj/fG5cr6mot+WXVeip9Tm/gVoWtBqu1skDQAAAABJRU5ErkJggg==");
      }

      .dx-icon-check::before {
        content: none !important;
      }

      a.dx-link.dx-icon-History.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAADRElEQVR4nMWVa0hTYRjH97n6GLqzzZPZDdQsd7aJ17VypdNK6EOSRCSBBBUSXbA+ZFBOULOMqGZBhlCkVHZBzZ1ZREVlMXDgLDOXTd3Z5cx2di194j226Zabng/RA384HDj/33N5n/PyeP8z3FqR0NsTl8RF0BmfCGewJTGNPSR/RUCHGQI6DLjId1/Qy9TiP5mahACjxo9HBQRI/g2u5n6Sb2Zq8Gm3GgckRo1PuWuFopDpqFxc+TVr47UBIvWIr0vwgnP23Zg+aB6URy3KY83HFUQ9XZQBlFIKA+L14LyS+JEz4Bn21q3GrwfFqPFGqItfyjNnEznI3BlFjkIElsC3rRvAXL4GmMsJ4L0pCsn/VPAHwtfM22+LPHmZVSmZRGb2Ahl8l4uBrkgdd53Pg8nqErDsLwFbcRYLG9tMwGBmCjiqEsF9IQHcjTj4HgggQGLD6MRFHerE5ox4aqvENqYgwJglddGGpwan6QM8unsLDG+6wDn8FqhDe1kISuBTbgp4OoThbSL5zYs4+Twebeq7jcyRLtfXwovONvYZiSpTAV0kg0FJGpjL14a1yE/ytQuau4b0cfRI31TQsOrYcbjXogkBbO0atoqR7HQY2pQ82yJUgRarjGluySfuWMtUr5HR1Yv1sH/fATi8IxNKiwtZc/2rDqD6n7OA0VwxGGXrI/cgdgWWfInJXpQZytb65R1Unz4NmqaG0Du79h4LMOWks3MIbxHWGxMwriBa0ce2lsaQoeNrH9AjfbMzqNjNAj7L0sC0a11YixYEGOXEcnuB9BeqwqFrZw2N7zvBPPBypqKms4D2ZWKLBIySVGBaRREtWgCA4rtCUu5QyaYdqgywHCwF66VqoOpOAbWnkM3ctk3KniDq6Kq/Fm1RABSmPEJp3S72z91uWiUD8yYChpUpYD+xcmbJ5i4aAuj43bzFBsrG80AIrsbV4KxbBT+aV4D3YcRihZ8g2kcKdkQ1dNeIpC41nh+Ut02gR5lFk/+xIBygw5w+HbZzXnPmHC6O/M0uRv5ICMnvmhfgqU3C2cuBI8B3l8O/iFHjJ9E1xwnSkAD+J3+qILF+Tw+GxxwsuqgDN4UZgRaRnIu8HfjKmMb/On4DoS7mzvJFr9cAAAAASUVORK5CYII=");
      }

      .dx-icon-history::before {
        content: none !important;
      }

      a.dx-link.dx-icon-validateInsurance.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAdgAAAHYBTnsmCAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEgSURBVDiNldO9LkRRFAXgD4nhCSTCNDQkplCrVV5ALRKtzivQSMz4SciISXTzCijGT7QE8QoqmcRPmEFxzpVr3JlhJae4e6+17tr73MtvFLCOKzyjjlsUMZnB/0YOW2iigTOUsYdTvMfeNgazxCf4RAUjGS/I4zByjjGQbhZjY7lTxIiVyN1OChMxWuUP4gTVqCnABt4w2kHQG1NW43MeHyjBDWpdxOUYezVVvxRuRx27qcY8ZjLEJfSkeBXhmj21GNTwirkOYthJDK5bRhjGfZyxnRimsUD46hpRmDa5wFob8Q9MRYP9bsQs9OEBQ1jEo7Ddf6MfR8LMBxjP4IwJI822M8kJC2vGcx7NysI+kvpStzQFbOIOL/Ekv3OhlfwFjgNO/GaqiEsAAAAASUVORK5CYII=");
      }

      .dx-icon-validateInsurance::before {
        content: none !important;
      }

      a.dx-link.dx-icon-CustomPreview.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVR4nO1SS05CQRCskGj0EKKewQO4UbrjngUewD1SHZYvRn53ADmGISwAIS6BuwCbxwLNG4zxzWMCcU0llXTS3TXdXQMc8T9I9QrCFsTmUFtBuXSxWBMPlcv9AsXoFMoG1NZQ+wpwDbEabp5OAiLlcyj76SYOIFZyVBt6uZ7ryUDZzYhEUe43n8S+mFgnLXJfuYbaxisq/TzwCeHExQU+emtu3D0PEhJOoBwfJrRtePOKhpnVhCNv/Xbo2L30VBy5KRKKfXgTv+M2OtvtXGKp8hXCOGi/MIbwJWz/X9xV8+4/ic0gXDgqp1DWUXi+2C9wBHbgG3DIrlSqcak8AAAAAElFTkSuQmCC");
      }

      .dx-icon-CustomPreview::before {
        content: none !important;
      }

      a.dx-link.dx-icon-GalleryIcon.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8klEQVR4nO2ZP2gUURDGv6iNKCoqgiAiqKAoCCqCjRGS25kLCYp4NgGxiLEIIZqd2UshXjBqp2ihRC0EFSSCpLBSkTSCoJVoYSkqWKgoVkKikd1NSG7/ZPcul909uQ+m2tu97/fmvXlvdoGGGmqoXK26FazdYC0mG9KLvO4F0ISq1N6/FiyPwDqZapC8RJu1vTLzxpnVYHmfunmehtAfyJk74wOw3E3dNPsg3qBUWhRtPmeuA+lE+QPkMUh6ptbCwgfJKbDcBskfjw8jGiCvhz30Y0hLJFc8Xs7HuEm7PNRDSEt532AOR9/kpnH23BtMwmuwF7PDU5Fu/n8A7KyZ+2AtVAbQXFoytbBHQHIdhrUvcQBD22dd/wvS3fEACoXFIH3uWSvjIPNYogCs5zxeu+IBsHaG1Oqv8Wp1jQBIBz0euuMCXAvdcIyBLdkHILFCMjCBnLmsDgBOrwfr9wCIGHU6CwC2DHMPSF852z3JL2daFc4sRd0ATMs2XcuFmzhAJWoZWAPSCyB57RzXSUZBFtUHgFHcBZJPwdVLrgZmkbMCQOZBsPwMLb2uuVHfGlowAEPYafVIP4P0AVqLG0PNs3UUpL/nND8TY2gtrlxYANK+gEbjG9rMA/6Rlx7/b6NC3oGtDbUHcA5veiN8Cjij3Dkzes7bhQqMlz3rA4z+bbUDsNPK8jTGn9snwrNOO1iteZ6VVZaL8wdgveOmdb6GahBUHUB2guIA5OVk6kZ5fhkopG6Uw0IuRQNQcVN2M2AdiQZwLzxJ3Sz74qNvpw4FYNnslrHMTJ1xGFbOv1GGATgXrR2ZKJ+kX5yjS5DmBJjefd23Y3YPPALWhwnGMEhOoLm0PNB8LICsi+odgGXIM926UEdqAumL8gyYHfG7K3fHTuZ7AXu/H2gfWJ/5KpXdskaKtcX/ASQDQXor3uizXk7dLPviLbh3RczpI+yc+9M3PTnVf9zDob5VqEgk+0GqyX87VjfsV5t5OT5nL95QQ6hK/wDAKm9uFcuudAAAAABJRU5ErkJggg==");
      }

      .dx-icon-GalleryIcon::before {
        content: none !important;
      }

      a.dx-link.dx-icon-changeOrder.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuUlEQVR4nM3QMWrDQBCF4c9NqjQ5Qk5gQy7gIj6EjxA39hGUPqdwkTq9C1/BpSEkEINrH8AgmYUphNBaEmryYNhZZvnfm+U/qUAVZ252wMMYQJWZDwJc8TIGUOVSFF0Ra+96AaqWGgTI6RWLoQlOmMXnrbHEYxegqXeU+MQffvHcJ0ERzmXc59hH/3UPUNcG29j/KYCpv/QFpJ1/sMMUH9Ef2wDNOofrd8sKby1mWaUPSzun2Ml5hckNtrZRrUsefVMAAAAASUVORK5CYII=");
      }

      .dx-icon-changeOrder::before {
        content: none !important;
      }

      a.dx-link.dx-icon-ViewSessionLog.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByElEQVR4nN2SP2gTYRyGg9AhYx0Cd/kM0iWLOGdwuWsGnewSyN2VQqyoiAgiHRrUgJQbVGwrGTwVhCR0KBRqe/nTO6RLCZF2CGZSidJWbZomdya0pBLsK3eJyfmHmoNOfeEZvuXh/V5+DsfxDhOrhZi4HvXP7NCOSOQE4RZEEpQlEkxKhG9B82kLSy0ERaKHlZBF9K3IJjQwscoIFVjwnOJkEC4Jwhuk4ObTbTKg+aUWggJaUEEJStEi0kNMvGJpJIv/bSIoEmU2et1tdGTxzcLpn9mjf71JIHPSw8sD/0Y1cbUhgazTOvYbNq6DTVQveHi5n3CL+6SzUwruzk6Z7k7mRgooXs11RGxcy7GJKgZj2+cNkTu42OhJIqi/i3yzG05z6HYM2WHfOffg7Vnv2Ir3r6/1mhcA/XQfypNdHDyu4buoIyoBfbYP8m5hqzS9BzyqA6IG3CsD1/K7U7YO8vTwHCbrB3hYAyaqwJ0SMPYZuJxvfLF1kESYfynuNH/crwDhLeD2JnDjEzBSaHbH7jWRMqLjX4FbG8D1j8Doe+BiVgvbFl1ZQ9/NdYSvfsDqpXdYHspp42eSlVeuZ5s+27I/Y0io5+upn+nceTKVaU1bAAAAAElFTkSuQmCC");
      }
      .dx-icon-ViewSessionLog::before {
        content: none !important;
      }

      a.dx-link.dx-icon-creditCreate.dx-link-icon {
        background-color: $base-bg-button-skyblue !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVR4nGNgoBRUdM/5TwlmoIoB5ALqG9C9/QOcxoep5oIVWw8SdkHXtjf/S1Zt+p+yoP1/4tzK/yWrOv+vO7vr/88/v/6fuHgbvwt+/v71v3HTlP9xc8owcNOmqf+PXLiO3wUgm5E1gQAyf/K2FfhdAHIuPgNyl7Tgd0H8nEq4RnQAEk+YV0mZC7IXNv/DawAotPEZ0LBqxjmseaFnzhpwFIFCuWxFL9ZYKFza8bV+/3wBgrk1dFUhZ+zs0oa4OaW342aX/gTTc8rqQeIgBQB+0t4n9qQ4xAAAAABJRU5ErkJggg==");
      }

      .dx-icon-creditCreate::before {
        content: none !important;
      }
    }

    a.dx-link.dx-icon-EditRevision.dx-link-icon {
      background-color: $base-bg-button-skyblue !important;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYElEQVR4nGNgoAawC95maB+4JZQYDFKLYYB94NZV9oFb/xOJV2EY0Dzv9oVJG578JwaD1GIYsPTYu3O77/3+jwtvvPX2/+qrj/9vv/P1P0gthgG77v1aDVI4/fgnMPaf+QCMfWZv/u86L+W/8wJ3MHZb5PM/YX3JY8c5XvIEDfCaM++/8wIPsMaA5SH/w1ZF/fda7AsxbL7ba6e5HloYBqy8/A2Mq7Zd+O+ywBNu8/HHJ/6DQMfhbriY0wL38/X19UxYDYhb3QNW5L7IG2zricenwAb0HO0D810XQgx3Wehhj9ULbvPSwQouv7zyHxuYenI6xCUL3YtwGJBBnAELXAtRDIDh0p0TifKC0zx3W6wGLL9y578LVBHOQJzv9hAeiNiA0wK3HOf57v+wRaPTArdfritcdfBkL5ghrp7OC9zPINn6x2mB2ynnpc5KBDUjA+eFzsLOc52V7Ofbc6BLAgB/POrdkvNyYwAAAABJRU5ErkJggg==");
    }

    .dx-icon-EditRevision::before {
      content: none !important;
    }
  }

  .dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row {
    height: 5px !important;
  }
}

tr.dx-row.dx-data-row.dx-column-lines {
  color: $base-gridlist-text;
}

.DatagridCss {
  .dx-datagrid-header-panel,
  .dx-datagrid-header-panel .dx-toolbar {
    background-color: #ffffff !important;
  }

  .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child,
  .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td,
  .dx-datagrid-rowsview .dx-row.dx-row-lines {
    border-top: none;
    color: $base-gridlist-text;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: 1px solid $base-grey;
  }

  .dx-datagrid .dx-row-lines > td {
    padding: 8px;
    padding-top: 10px;
  }

  .dx-pager .dx-pages {
    white-space: nowrap;
    float: none;
  }

  .dx-datagrid-header-panel,
  .dx-widget.dx-datagrid-pager.dx-pager {
    padding: 0% !important;
  }

  .dx-datagrid .dx-row-lines > td {
    padding: 12px 12px !important;
    vertical-align: middle !important;
    @extend .font-Poppins-regular-16;
  }

  .dx-datagrid-headers {
    @extend .font-Poppins-semiBold-16;
    background-color: $base-gridList-header;
    color: #3c3c3c;
    border-radius: 4px 4px 0 0;
    padding: 8px;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: none;
  }

  .dx-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview {
    border-radius: 0 0 4px 4px;
  }

  .dx-datagrid-search-panel.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 2px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    opacity: 1;
    margin: 0 0 0 2px !important;
  }

  .dx-row-lines {
    border-top: none;
    color: #80838f;
    padding: 14px;
  }
}

.text-green-gridcell {
  color: $base-light-green-gridcell;
}

.text-red-gridcell {
  color: $base-light-red-gridcell;
}

.color-smokey-grey {
  color: $base-smokey-grey;
}

.mt-6 {
  margin-top: 65px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.Gridcontainer {
  background: #ffffff;
  margin-top: 1em;
}

.fieldLabel {
  @extend .font-Poppins-bold-14;
  color: $base-label-dark-grey;
}

.fieldLabelforCompare {
  @extend .font-Poppins-bold-14;
  color: $base-label-dark-grey;
  width: 146px !important;
}
.fieldLabelforComparelg {
  @extend .font-Poppins-bold-14;
  color: $base-label-dark-grey;
  width: 135px !important;
}

.otherFieldLabel {
  @extend .font-Poppins-regular-16;
  color: $base-label-dark-grey;
}

.dx-texteditor-input-container {
  input.dx-texteditor-input {
    @extend .font-Poppins-bold-14;
    color: $base-label-dark-grey;
  }
}

.additionalGridCss {
  .dx-datagrid-header-panel,
  .dx-widget.dx-datagrid-pager.dx-pager {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 0em;
    // margin-left: 0.5em;
  }

  .dx-widget.dx-datagrid-pager.dx-pager {
    .dx-pages {
      .dx-info {
        padding-left: 0.3em;
      }
    }
  }

  .dx-datagrid-header-panel .dx-toolbar {
    margin-bottom: 0px;
    border-top: solid 1px $base-grey;
    border-left: solid 1px $base-grey;
    border-right: solid 1px $base-grey;
    padding: 0.3em 0.5em 0.3em 0.5em;
    border-radius: 5px 5px 0 0;
    background: $base-light-editor 0% 0% no-repeat padding-box !important;
    opacity: 1;
  }

  .dx-datagrid {
    border-radius: 4px 4px 0px 0px;
    border: solid 1px $base-grey;

    .dx-datagrid-headers {
      margin: 0.8em 0.8em 0 0.8em;
    }

    .dx-datagrid-rowsview {
      margin: 0 0.8em 0 0.8em;
    }

    .dx-datagrid-total-footer {
      margin: 0 0.8em 0.8em 0.8em;
    }
  }
}

.btnGridAddRow {
  border: none !important;
  background-color: transparent !important;

  .dx-button-content {
    padding: 0px;
    border-style: none;
    color: $base-accent;

    i.dx-icon.dx-icon-plus {
      color: $base-accent;
    }
  }

  .btnGridAddRow:hover,
  .btnGridAddRow:focus {
    background-color: transparent !important;
  }
}

.darkFontColor {
  color: $base-accent;
}

.DeleteProjectFontColor {
  color: #af521c !important;
}
.messageHightliter {
  color: red !important;
}

.lightFontColor {
  color: $base-lightaccent;
}

@media screen and (min-width: 768px) {
  .displayContentFlex {
    display: flex !important;
    flex-wrap: wrap;
  }
}

@media (max-width: 374px) {
  .popupset,
  .bgPopup {
    .dx-toolbar-label .dx-toolbar-item-content > div {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 374px) {
  .importrptTemplates,
  .bgPopup {
    .dx-toolbar-label .dx-toolbar-item-content > div {
      font-size: 12px !important;
    }
  }
}

@media (min-width: 375px) and (max-width: 479px) {
  .popupset,
  .bgPopup {
    .dx-toolbar-label .dx-toolbar-item-content > div {
      font-size: 14px !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .popupset,
  .bgPopup {
    .dx-toolbar-label .dx-toolbar-item-content > div {
      font-size: 14px !important;
    }
  }
}

.paddingPoint8 {
  padding: 0.8rem !important;
}

/// For Html Editor
.dx-htmleditor-toolbar {
  background: #e6eef2 0% 0% no-repeat padding-box !important;
  opacity: 1;

  .dx-toolbar-items-container {
    .dx-toolbar-before {
      padding-right: 0px !important;
    }

    .dx-toolbar-after {
      .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-icon.dx-dropdownmenu.dx-dropdownmenu-button {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
  }
}

.dx-htmleditor .dx-htmleditor-toolbar-wrapper {
  padding: 0 !important;
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  font-size: 12px;
}

.dx-dialog-message {
  padding: 0.5em;
}

.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag
  .dx-treeview-node-container:first-child
  > .dx-treeview-node-is-leaf {
  padding: 0px 10px !important;
}

.dx-datagrid-rowsview .dx-row.dx-group-row {
  color: $base-accent !important;
  font-weight: bold;
}

.dx-datagrid-group-closed,
.dx-datagrid-group-opened {
  color: #959595 !important;
}

.gridWhiteBgToolbar {
  .dx-datagrid-header-panel,
  .dx-datagrid-header-panel .dx-toolbar {
    background-color: $base-white !important;
  }
}

.ForValidationAstrikMark .dx-label span::after {
  content: " *";
  color: $base-dark-red;
}

// no need of this line, this will disturb the ui of export button
// .ListHeaderBtn {
//   .dx-button-has-icon .dx-button-content {
//     padding: 7px !important;
//   }
// }

.paddingRight4rem {
  padding-right: 3.8rem !important;
}

/* For Primary & Secondary Contact Section */
.borderColorPrimary {
  border-color: $base-lightaccent !important;
  border-radius: 6px !important;
}

.borderColorSecondary {
  border-color: $base-accent !important;
  border-radius: 6px !important;
}

.bgColorPrimary {
  background-color: $base-lightaccent !important;
}

.bgColorSecondary {
  background-color: $base-accent !important;
}

.PrimaryContactBtn {
  .dx-button-has-icon .dx-button-content {
    padding: 1px !important;
  }

  .btnPrimarySearch {
    border: none;
    margin-right: 0.5em;
    background-color: transparent !important;
    border: none !important;

    i.dx-icon.dx-icon-search {
      color: $base-white !important;
    }
  }

  .btnPrimaryClear {
    border: none;
    margin-right: 0.5em;
    background-color: transparent !important;
    border: none !important;

    i.dx-icon.dx-icon-clear {
      color: $base-dark-red !important;
    }
  }
}

.btnPrimarySearch:hover,
.btnPrimaryClear:hover {
  background-color: transparent !important;
  border: none !important;
}

.btnPrimarySearch:focus,
.btnPrimaryClear:focus {
  background-color: transparent !important;
}

.backcolortopheadingInlead {
  background-color: $base-leadtopBack;
}

/* For Primary & Secondary Contact Section */

// add EmailList

.addNewMarketing {
  border: none !important;
  background-color: transparent !important;

  i.dx-icon.dx-icon-plus {
    color: $base-white !important;
  }

  .dx-button-content {
    padding: 0 !important;
  }
}

.addNewMarketing:focus {
  background-color: transparent !important;
}

///

/* For Primary & Secondary Contact Section */
.borderColorPrimary {
  border-color: $base-lightaccent !important;
  border-radius: 6px !important;
}

.borderColorSecondary {
  border-color: $base-accent !important;
  border-radius: 6px !important;
}

.bgColorPrimary {
  background-color: $base-lightaccent !important;
}

.bgColorSecondary {
  background-color: $base-accent !important;
}

.PrimaryContactBtn {
  .dx-button-has-icon .dx-button-content {
    padding: 1px !important;
  }

  .btnPrimarySearch {
    border: none;
    margin-right: 0.5em;
    background-color: transparent !important;
    border: none !important;

    i.dx-icon.dx-icon-search {
      color: $base-white !important;
    }
  }

  .btnPrimaryClear {
    border: none;
    margin-right: 0.5em;
    background-color: transparent !important;
    border: none !important;

    i.dx-icon.dx-icon-clear {
      color: $base-dark-red !important;
    }
  }
}

.btnPrimarySearch:hover,
.btnPrimaryClear:hover {
  background-color: transparent !important;
  border: none !important;
}

.btnPrimarySearch:focus,
.btnPrimaryClear:focus {
  background-color: transparent !important;
}

.backcolortopheadingInlead {
  background-color: $base-leadtopBack;
}

/* For Primary & Secondary Contact Section */

.colorWhite {
  color: $base-white;
}

.colorBlack {
  color: black;
}

// .dx-colorview-controls-container {
//   position: relative;
//   width: 90px;
//   margin-left: 20px !important;
// }
.dx-colorview-controls-container {
  margin-left: 17px !important;
}

.dx-colorview-container label .dx-texteditor {
  width: 73px !important;
}

.dx-tag-content {
  background-color: transparent !important;
  font-weight: bold !important;
  margin: 2.6px 0 0 4px !important;

  .dx-tag-remove-button {
    top: -1 !important;
  }
}

.AddUserHint {
  pointer-events: auto;
}

.BreakWordForLongText {
  word-break: break-all !important;

  .dx-texteditor-input {
    height: 55px !important;
  }
}
.BreakWordForLongTextCompanyDescription {
  word-break: break-all !important;

  .dx-texteditor-input {
    height: 200px !important;
  }
}

.breakwordforreciepnts {
  word-break: break-all !important;

  .dx-texteditor-input {
    height: 75px !important;
    @extend .font-Poppins-bold-14;
    color: $base-label-dark-grey;
  }
}
///Chart Of Account
.treeList {
  max-height: 80vh;
  font-size: 13.57px !important;
  font-weight: bold;
  padding: 1.4em;
}

.treeList .dx-header-row > td {
  @extend .font-Poppins-semiBold-13;
  background: $base-accent; // //$base-light-gridheader;
  color: $base-menu;
  padding: 0.8em 0;

  .dx-datagrid-text-content {
    padding-left: 8px !important;
  }
}

.treeList tr.dx-row.dx-data-row.dx-row-lines {
  @extend .font-Poppins-medium-13;
  text-align: left;
  letter-spacing: 0px;
  color: $base-grid-forecolor;
  opacity: 1;
}

.treeList tr.dx-row.dx-data-row.dx-row-lines.dx-state-hover {
  background: rgba(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
}

.dx-treelist-header-panel {
  background-color: $base-bg-skyblue !important;

  .dx-toolbar {
    background-color: $base-bg-skyblue !important;
  }
}

.dx-treelist-headers {
  border-radius: 6px 6px 0px 0px !important;

  .dx-treelist-content {
    border-radius: 6px 6px 0px 0px !important;
  }
}

.dx-treelist-header-panel,
.dx-widget.dx-treelist-pager.dx-pager {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  // margin-top: 0.5em;
}

.dx-widget.dx-treelist-pager.dx-pager {
  @extend .font-Poppins-medium-13;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.dx-treelist-rowsview .dx-row {
  border-bottom: 2px solid #e3e3e3;
}

.dx-treelist-rowsview .dx-row-focused.dx-treelist-row > td {
  border: none !important;
}

.treelist tr.dx-row.dx-column-lines.dx-header-row {
  background: transparent
    linear-gradient(90deg, $base-lightaccent 0%, $base-accent 100%) 0% 0%
    no-repeat;
  color: $base-white;
}

.dx-treelist-search-panel.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget {
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 2px solid rgba(242, 242, 242, 1);
  border-radius: 11px;
  opacity: 1;
  margin: 0 0 0 2px !important;
}

.dx-widget.dx-treelist-pager.dx-pager {
  @extend .font-Poppins-medium-13;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.dx-selection td {
  &:not([role="columnheader"]) {
    background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
  }
}

.dx-row.dx-data-row.dx-column-lines.dx-selection {
  &:not([role="columnheader"]) {
    background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
  }
}

.dx-row-focused td {
  background: rgb(102, 235, 191, 0.3) 0% 0% no-repeat padding-box !important;
  color: $base-grid-forecolor !important;
}

.treeList tr.dx-row.dx-data-row.dx-row-lines[aria-expanded] {
  td.dx-treelist-cell-expandable {
    color: #004875 !important;
    font-weight: 700;
  }
}

.dx-treelist-table .dx-row-lines {
  td.dx-command-edit {
    padding: 0 !important;
    vertical-align: middle;

    a {
      border-radius: 6px;
      background-repeat: no-repeat;
      padding: 12px !important;
      background-position: center !important;
      background-size: 12px 12px !important;
    }

    a.dx-link.dx-icon-edit.dx-link-icon {
      background-color: $base-bg-button-skyblue !important;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAABHNCSVQICAgIfAhkiAAAAZFJREFUWEft1j1WwkAQB/CZFGjHHsEb6A2EQh8tJxBvkAZasfPFQm4g3oAy2hhvIDfZZxd8j3ETAWHd3WSXbEhB2nz98s/sziA06MAGWeCI0f0N78mwq+HFMsCbgOCDv0UzU1l4xWQQCvBdAFiGQKRbHj9Oa09GhqwBJpC3ZNrXo0Qsj0tVCjqQNwzrhIxOWonAnKtBQZfHD9n5zVEp5vfXwBOm332eTLgJpEqnMoxUI5+YLro6ECG8fMXRQE6sEoymWJUgHSRfbfu2A92qWT13BwSnrZDH0djL0lZA5ohBSLTMNre2DCr6cOdklJB00cnrpDcaE8Hd394C96ZENtcVaVXnzZDhgAif1/eZamTvAvYFsS5gnxArjG9IaUwdkFKYuiCFmDohRkzdEC3mEBAl5lAQJUaa0OZiFFht8e47a9ld/l9v2saIppdPY6znH1KYDAFOAYgLcejSa8omom2UpkHapunZQgqT2X6gb4h6NYn6EAPg2c6XEXD+Gk1cvtbmHufhyuYlZa89YnRJNSqZHyc3XjNdBrgQAAAAAElFTkSuQmCC");
    }

    .dx-icon-edit::before {
      content: none !important;
    }

    a.dx-link.dx-icon-trash.dx-link-icon {
      background-color: $base-bg-button-pink !important;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAZCAYAAAA14t7uAAAABHNCSVQICAgIfAhkiAAAAK5JREFUSEtjZMABjquoHABK2eOSh4o7Wt65A1KHARiBBvwnoJkc6UbaGQxyDtDVDUCqHogbgV4DsUkGMJ8D9TOCNIOJ4WMwuvfw+Y6koBg1GJ7cRoNiNCgwC6HRVIGcKhxABQ9y9XNcQ0OB4c8fBQYWlgeWN248gBXcJBVCpJT2A2Tw//8PGBgZ4d4jxcVAtbBgQ6qaIOF2ASjJT6Jh6MrhdSbYdKQIANtKFkCLTACtDUlHzwLWTQAAAABJRU5ErkJggg==");
    }

    .dx-icon-trash::before {
      content: none !important;
    }

    a.dx-link.dx-icon-plus.dx-link-icon {
      background-color: $base-bg-button-skyblue !important;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABHNCSVQICAgIfAhkiAAAAodJREFUOE+VVE1oE2EQndkYa1ptIlTU0kNvWtBLrYIe9KLZUIjWQ649tP6AqKSUbEQLBqHSbBVFD1JpK4LgoQcRQZMICtEeFKlIDwoqFcGeRLKpRq0k4/uWbNzGpGIgYb+ZN++b92ayTDU+AT3WK8QHhWgHk2wk4p+AvWOWxyXhW/m0+by6jN2B5lBcZ5ELiG1B8VcieYLnTwA1CFMHCXWV8TeaVjYen7+XKDj1FSJ/yOgHcByJLyQ8ZO3yjVEiUXJftCY4uFljzyBih/Cd8ZYo/PmhOa8wNlG5kxSIXrCmRXKpkQ+1JDuxQDDWJ8wTOGettLmnQuTXjVnIaGX2bPsXSYVMN6Lw8BIuH7Iy5jCXjb0JOcesTPJadSe4ZA7gKYCN6lwgZDwF2SYrZa5jAO9A4V5rp89f7YkqRB4DpOsAH/2LqCxRE97PzbqhpvIKWrtr+bIsUehUu0hpjonPoaMYJsMTIDrsENlyiNprGi40+kdmQvPrhSKJjClp39HRVC5t9laIgoZJGvnts9AR/L6GPLVTxML3c+nkXfXcFhnwLeS9BWa+qohmAfoBD7b/rzTYgs2nZ5AW5UAodkWET5Sk2LGQufimxtTqmu3X48No+TR2r1OZbbOCYNztk1smWp925Djxpu7YhhVFfovzjFpKe7PBjC2VPvzP+nOZ0cnltto1EKwN9WDDQ/lUMm0TtYYTjd8WC8rMTgQGYPzlemTlTtTi9qixo9Oz9hCcgpZ9RusvjW7jvJuZpqkkk6R5HuVSqz62RayGfN67FYUH0PlJYFa7SZYQuTw5g9FHMfaWOl1lIee8kuPOL3kfuRNrg/Ew3kFd2Nz1mMoiiN+Txtncg5GXtS74DbL7Co7Vif5JAAAAAElFTkSuQmCC");
    }

    .dx-icon-plus::before {
      content: none !important;
    }
  }
}

/// End Chart Of Acoount

.scroll-btn {
  top: calc(100% - 60px);
  left: calc(100% - 70px);
  position: absolute;

  .scroll-btn-customize {
    opacity: 0.5;
    animation: showMe 2s forwards;
  }
}

@keyframes showMe {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.headerGridBtns
  .dx-item-content.dx-list-item-content
  .dx-toolbar-item-auto-hide
  .dx-item-content.dx-toolbar-item-content
  .ExportHeaderBtn {
  padding: 0% !important;
}

.dx-editor-outlined.dx-texteditor-with-floating-label,
.dx-editor-outlined.dx-texteditor-with-label .homeCustomFilter {
  margin-left: 5px !important;
}

.TextLeftListBtn {
  .dx-button-content {
    flex: 0 0 auto !important;
  }
}

.CostControlTextLeftListBtn {
  .dx-button-content {
    flex: 0 0 auto !important;
  }
}

@media (max-width: 767px) {
  .popupset,
  .TextLeftListBtn {
    .dx-button-content {
      padding: 8px 12px !important;
    }
  }
}

.IncreaseToolbarHeight {
  .dx-toolbar .dx-toolbar-items-container {
    height: 75px !important;
    overflow: visible;
  }
}

// hide browser eye icon for password field

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.CreditButtons {
  background: #075f97 0% 0% no-repeat padding-box !important;
  width: 100%;
  color: #ffffff;
  font-weight: bold;
}

.dx-filemanager-dialog {
  color: $base-accent !important;
  font-weight: 600;
  padding: 0.5em !important;
}

.dx-formdialog-form {
  padding: 0.8em !important;
}

/* for google map */
.gm-fullscreen-control {
  display: none !important;
}

.gm-style-iw .gm-style-iw-c {
  border: 0px solid transparent !important;
}

.gm-ui-hover-effect {
  border: 0px solid transparent !important;
  outline: none !important;
}

.gm-ui-hover-effect > span {
  border: 0px solid transparent !important;
  outline: none !important;
}

.descriptionWrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: contents;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.descriptionNoWrap {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.HeaderBackButton {
  background: $base-accent 0% 0% no-repeat padding-box !important;
  color: $base-white !important;
}

/// For Repor Designer
.dxrd-designer-wrapper .dxrd-surface-wrapper {
  width: 0 !important;
}

/// End Css Report Designer

.dx-list-search {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.dx-treeview-search {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

// For Validation
.dx-datagrid-invalid::after {
  border: 1px solid red !important;
}

.ListCollapseExpandButon {
  background: $base-yellow 0% 0% no-repeat padding-box !important;

  .dx-button-content {
    padding: 6px !important;
  }

  i.dx-icon.dx-icon-chevronnext,
  i.dx-icon.dx-icon-chevrondown {
    color: $base-white !important;
    // font-size: 16px !important;
  }
}

.InsuranceInfo {
  color: $base-text-color !important;
  font-weight: bold;
}

.NoInsuranceInfo {
  color: $base-dark-red !important;
  font-weight: bold;
}

.ValidInsuranceInfo {
  color: $base-green !important;
  font-weight: bold;
}

.ExpiringInsuranceInfo {
  color: #ff8628 !important;
  font-weight: bold;
}

.BtnHeaderAddRow {
  background: transparent 0% 0% no-repeat padding-box !important;
  border: none !important;

  .dx-button-content {
    padding: 0px !important;
  }

  i.dx-icon.dx-icon-plus {
    color: $base-white !important;
  }
}

// For Project Multi lookup filter on List
#multiProjectFilterTagBox,
.SubcontractorMultiTagBox {
  .dx-list-select-all {
    display: none !important;
  }
}

.totalTextAlignment {
  text-align: right !important;
  border: none !important;

  .dx-datagrid-summary-item {
    background-color: $base-white !important;
    padding: 4px 0px 4px 0px;
    border-radius: 4px;
    text-align: center;
  }
}

.messageover {
  .dx-invalid-message > .dx-overlay-content {
    //     color: #fff;
    //     background-color: #d9534f;
    //     border-radius: 4px;
    padding: 18px;
    margin-top: 30px;
    word-wrap: break-word !important;
  }
}

.messageoverLarge {
  .dx-invalid-message > .dx-overlay-content {
    padding: 10px;
    margin-top: 35px;
    word-wrap: break-word !important;
    width: auto !important;
    min-width: 315px;
  }
}

#accountbillInfoDatagrid {
  .dx-datagrid-revert-tooltip .dx-revert-button {
    display: none;
  }
}

.dx-calendar {
  width: 282px !important;
}

.textStyleDeleted {
  font-style: italic;
  color: $base-accent;
}

.note {
  display: block;
  font-size: 9pt;
  color: $base-red !important;
  margin-left: 9px;
  font-style: italic;
}
.NoteBilreciept {
  display: block;
  color: $base-red !important;
  margin-left: 9px;
}

.Additionalfeesmessage_default {
  display: block;
  color: $base-red !important;
  margin-left: 20px;
}
// For Help Icon
.dx-toolbar-before {
  .dx-item.dx-toolbar-item.dx-toolbar-label {
    max-width: fit-content !important;
  }
}

.HelpButtonIcon {
  .dx-button {
    border: none !important;
    background-color: transparent !important;

    .dx-button-content {
      padding: 0px !important;
    }

    i.dx-icon.dx-icon-help {
      color: #bfc8cf !important;
    }
  }
}

b.alertText {
  color: $base-accent;
}

.topMarginForBackButton {
  margin-top: 5px;
}

.ButtomPosition_forFormula {
  margin-bottom: 1.25rem !important;
  margin-top: 0.5rem !important;
}

.formulahint {
  color: #737373 !important;
  font-style: italic;
  justify-content: left;
  display: flex;
}

.InfoTooltipContentForProjectExpenses {
  .tooltip-label {
    //@extend .font-Poppins-regular-14;
    font-style: normal;
    // font-weight: bold;
    padding: 1px;
    padding-bottom: 2px;
    color: $base-label-dark-grey;
  }
}

.textFontColorForaccount {
  color: $base-accent;
  font-weight: 800;
}

.ManageTagBoxOverlapping {
  .dx-tag {
    max-width: calc(100% - 1px);
    display: inline-table !important;
  }
}

.CreditLinkable {
  text-decoration: underline $base-accent;
  color: $base-accent;
  cursor: pointer;
}

// Start CSS to show validation Message in 3 dots menu for responsviness //

.dx-list-item[role="menuitem"] {
  overflow: visible !important;

  .dx-invalid-message > .dx-overlay-content {
    padding: 3px;
  }

  .dx-list-item-content {
    overflow: visible !important;
    text-overflow: clip !important;
    white-space: initial !important;
  }
}

//End CSS to show validation Message in 3 dots menu for responsviness //

.TextColorandStyle {
  color: $base-label-dark-grey !important;
  font-size: 12px;
  font-weight: bold;
  font-style: italic !important;
}

.RptFileImportSection {
  word-break: break-all;
  word-wrap: normal;

  #dropzone-external {
    // width: 140px;
    height: 140px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 1px;
    border-style: solid;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #dropzone-external > * {
    pointer-events: none;
  }

  #dropzone-external.dropzone-active {
    border-style: solid;
  }

  #dropzone-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  #dropzone-text {
    @extend .font-Poppins-regular-12;
    margin-left: 0.5em;

    span {
      font-weight: 100;
      //opacity: 0.5;
    }
  }

  #upload-progress {
    display: flex;
    margin-top: 10px;
  }

  .maxImageSizeSpan {
    font-size: 10px;
    color: red;
  }

  .maxImageSizeValidation {
    color: red;
  }

  .btnRemoveImage {
    border: none;
    background-color: transparent !important;

    .dx-button-content {
      padding: 1 !important;

      i.dx-icon.dx-icon-trash {
        color: $base-dark-red !important;
      }
    }
  }
}

//  start Change CheckBox Contrast Colour //

.dx-checkbox-icon {
  border: 2px solid $base-icon-color !important;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: $base-icon-color !important;
}

//  end Change CheckBox Contrast Colour //

// start setting caret color for html editor //

.HTMLEditorCommon .dx-htmleditor-content * {
  caret-color: auto !important;
}
// end setting caret color for html editor //

#FileControlForCostControl,
#ProposalFileAttachmentManager,
#fileAttachmentManager,
#ProjectAttachmentGrid,
#PortfolioItemAttachmentGrid,
#CostControlFileAttachmentManager,
#ReceiptAttachmentGrid,
#JobScheduleTaskFileAttachmentManager {
  .dx-drawer-shrink .dx-drawer-panel-content {
    display: none !important;
  }
  .dx-filemanager-notification-container {
    display: none !important;
  }
}

#FileControlForCostControl {
  .dx-filemanager-items-panel {
    .dx-widget.dx-filemanager-breadcrumbs {
      display: none !important;
    }
  }
}
#AccountPaymentInfoDatagrid {
  .dx-datagrid-revert-tooltip {
    display: none;
  }
}

// Start Hide Async Rule Validation Icons

.PrintTemplateAsyncValidation {
  .dx-valid .dx-texteditor-input-container:after {
    content: none !important;
  }

  .dx-pending-indicator.dx-loadindicator.dx-widget {
    display: none !important;
  }
}
// End Hide Async Rule Validation Icons

.TileViewList {
  .dx-switch-handle::before {
    background-color: $base-white !important;
    border-radius: 100% !important;
    width: 16px;
    height: 16px;
  }

  .dx-switch-container {
    background: transparent
      linear-gradient(90deg, $base-lightaccent 0%, $base-lightaccent 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 20px !important;
    height: 22px !important;
  }
}

.Datagrid_ListView {
  .dx-switch-handle::before {
    background-color: $base-white !important;
    border-radius: 100% !important;
    width: 16px;
    height: 16px;
  }

  .dx-switch-container {
    background: transparent
      linear-gradient(90deg, $base-accent 0%, $base-accent 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 20px !important;
    height: 22px !important;
  }
}
.CopyPortfolioLink {
  background: transparent !important;
  border: none !important;
}
.CopyLinkClass {
  .dx-toast-info {
    @extend .font-Poppins-semiBold-12;
    background-color: #337ab7;
    padding: 5px !important;
  }
  .dx-toast-icon {
    width: 16px !important;
    height: 16px !important;
  }
}

.AfterSwitch {
  .dx-switch-handle::before {
    background-color: #ffffff !important;
    border-radius: 100% !important;
    width: 15px !important;
    height: 15px !important;
    // margin-right: 5px !important;
  }

  .dx-switch-on {
    width: 49%;
  }

  .dx-switch-container {
    background: transparent
      linear-gradient(
        90deg,
        $base-dark-green-button 0%,
        $base-dark-green-button 100%
      )
      0% 0% no-repeat padding-box;
    border-radius: 20px !important;
    height: 21px !important;
    border-color: transparent !important;
  }
}

.BeforeSwitch {
  .dx-switch-handle::before {
    background-color: #ffffff !important;
    border-radius: 100% !important;
    width: 15px !important;
    height: 15px !important;
  }

  .dx-switch-container {
    background: transparent
      linear-gradient(90deg, $base-grey 0%, $base-grey 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 20px !important;
    height: 21px !important;
    border-color: transparent !important;
  }
}
.ApprovecommissionFile {
  //border: 1px solid $base-accent !important;
}

.AsyncRuleHideIndicator {
  .dx-valid .dx-texteditor-input-container:after {
    content: none !important;
  }

  .dx-pending-indicator.dx-loadindicator.dx-widget {
    display: none !important;
  }
}

.SignatureCertificateImg {
  i.dx-icon.dx-icon-export {
    color: $base-white !important;
  }
}

.BIDashboardDatagridCss {
  .dx-datagrid-header-panel,
  .dx-datagrid-header-panel .dx-toolbar {
    background-color: #ffffff !important;
  }

  span.dx-header-filter.dx-header-filter-empty {
    color: $base-accent !important;
  }

  .dx-datagrid-total-footer > .dx-datagrid-content {
    border-bottom: 1px solid $base-grey !important;
    border-left: 1px solid $base-grey !important;
    border-right: 1px solid $base-grey !important;
    border-radius: 4px;
    padding: 0px !important;
  }
  .dx-datagrid-summary-item {
    color: $base-accent !important;
  }
  .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child,
  .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td,
  .dx-datagrid-rowsview .dx-row.dx-row-lines {
    border-top: none;
    color: $base-gridlist-text;
  }

  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
    border-bottom: 1px solid $base-grey;
  }

  .dx-datagrid .dx-row-lines > td {
    padding: 8px;
    padding-top: 10px;
  }

  .dx-pager .dx-pages {
    white-space: nowrap;
    float: none;
  }

  .dx-datagrid-header-panel,
  .dx-widget.dx-datagrid-pager.dx-pager {
    padding: 0% !important;
  }

  .dx-datagrid .dx-row-lines > td {
    padding: 12px 12px !important;
    vertical-align: middle !important;
    @extend .font-Poppins-regular-14;
  }

  .dx-datagrid-headers {
    @extend .font-Poppins-semiBold-12;
    background-color: $base-gridList-header;
    color: #3c3c3c;
    border-radius: 4px 4px 0 0;
    padding: 8px;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: none;
  }

  .dx-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview {
    border-radius: 0 0 4px 4px;
  }

  .dx-datagrid-search-panel.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 2px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    opacity: 1;
    margin: 0 0 0 2px !important;
  }

  .dx-row-lines {
    border-top: none;
    color: #80838f;
    padding: 14px;
  }
}
