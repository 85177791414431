@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-display: swap;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif !important;
  font-display: swap;
}

/* Target all contenteditable elements to hide the caret */
[contenteditable="true"] {
  caret-color: transparent;
}

/* Hide caret for all elements by default */
* {
  caret-color: transparent;
}

/* Allow caret for input and textarea unless they are readonly or disabled */
input:not([readonly]):not([disabled]),
textarea:not([readonly]):not([disabled]) {
  caret-color: auto;
}