$base-accent: #004875;
$base-text-color: rgba(0, 0, 0, 0.87);
$base-white: #ffffff;
$base-border-color: #e0e0e0;
$base-border-radius: 4px;
$base-whitesmoke:whitesmoke;
$base-lightaccent:#1794E6;
$base-light-gridheader:#148AD7;
$base-blackbase:#00000033;
$base-grey:#ddd;
$base-lemon-grass:#999;
$base-smokey-grey:#707070;
$base-light-green-button:#66ebbf;
$base-dark-green-button:#00b87b;
$base-light-red:#eb6666;
$base-dark-red:#E15858;
$base-green:green;
$base-light-editor:#e6eef2;
$base-yellow:#fbb03b;
$base-red:red;
$base-ceramic-white:#fefeff;
$base-bg-skyblue:#F4F7FC;
$base-menu:#C3DAF2; 
$base-menu-select:#F1CC01;
$base-dark-menu:#143754;
$base-label-light-grey:#636363;
$base-label-dark-grey:#2D2F39;
$base-dark-green:#006837;
$base-gridlist-text:#80838F;
$base-gridList-header:#F2F7FA;
$base-light-green-gridcell:#50CD89;
$base-light-red-gridcell:#F1416C;
$base-meu-border:#D1D9E7;
$base-grid-forecolor:#4D4D4D;
$base-grid-border:#9EAACC;
$base-popup-title:#E6E6E6;
$base-grid-footer-border:#D6D6D6;
$base-bg-button-skyblue:#DCE3F8;
$base-bg-button-pink:#F8CED6;
$base-button-bg:#075F97;
$base-scope-bg: #F0CB00;
$base-btn-bg:#DCE3F8;
$base-addnew-button:#009245;
$base-backForMarketing:#FFF6D1;
$base-TimeText : #474747;
$base-NoteSchedule : #878787;
$base-leadtopBack :#F2F2F2;
$base-linkcolor : #3B98DF;
$base-bgresetText : #D4EDDA;
 $base-resetText: #40634B;
 $base-icon-color:#0F4776;
 $base-Icon-checked:#337ab7;
$base-dark-tooltip:rgba(26, 26, 26, 0.733);